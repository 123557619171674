<template>
  <div>
    <v-card class="mt-9">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h5 class="font-weight-light">LIST OF SECURITY DEPOSITS</h5>
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table dense
                    :headers="headers"
                    :items="data_items"
                    :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Data</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearch"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.id }}
            </td>
            <td>
              {{ item.branch_information.branch_code }}
            </td>
            <td>
              {{ item.branch_data.room_no }}
            </td>
            <td>
              {{ item.members_information.company_name }}
            </td>
            <td>
              {{ item.or_date }}
            </td>
            <td>
              {{ item.or_no }}
            </td>
            <td>
              {{ formatPrice(item.amount)}}
            </td>
            <td>
              {{ item.remarks }}
            </td>
            <td>
              <v-chip color="error" v-if="item.deposit_slip_id===null">
                UNPAID
              </v-chip>
              <v-chip color="success" v-else-if="item.deposit_slip_id!=null&&item.is_consumed===0">
                PAID
              </v-chip>
              <v-chip color="warning" v-else-if="item.deposit_slip_id!=null&&item.is_consumed===1">
                CONSUMED
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="is_view_rooms" max-width="90%">
      <BranchRooms :selected_branch="this.selected_branch"/>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose
    , mdiAccountOff
    , mdiAccountCheck
    , mdiPlus
    , mdiEyeCircle
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import BranchRooms from '@/views/register_interface/branch_component/BranchRooms'

  const initialState = () => {
    return {
      is_view_rooms: false,
      selected_branch: {},
      data_items: [],
      search: '',
      headers: [
        {text: 'ID', value: 'id', sortable: true},
        {text: 'Branch', value: 'branch_information.branch_code', sortable: true},
        {text: 'Room #', value: 'branch_data.room_no', sortable: true},
        {text: 'Name', value: 'members_information.company_name', sortable: true},
        {text: 'OR Date', value: 'or_date', sortable: true},
        {text: 'OR #', value: 'or_no', sortable: true},
        {text: 'Amount', value: 'amount', sortable: true},
        {text: 'Remarks', value: 'remarks', sortable: true},
        {text: 'Status', value: 'status', sortable: true},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
      BranchRooms,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiAccountOff
          , mdiAccountCheck
          , mdiPlus
          , mdiEyeCircle
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_data_payments', ['list_of_security_deposit']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      initialize_data() {
        const data = new FormData()
        data.append('is_consumed', 0);
        this.list_of_security_deposit(data)
          .then(response => {
            this.data_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      view_rooms(item) {
        this.selected_branch = item
        this.is_view_rooms = true
      },
    },
  }
</script>
